import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { getCurrentOrder } from "../../back/inc/controllers/order.ts";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from "../Server.tsx";

export function BasketPanel(/*{ data }*/){

	const hasData = false;//!!data;
	
	//retreive basket
	//let corder = await getCurrentOrder({account: session});

	let body = null;
	if(hasData){

		body = (
			<div class="gbg">
				<div class="p-4 mx-auto max-w-screen-md">
				<div class="d-flex align-items-center justify-content-left">
					<div class="bg-white rounded p-2">
						<img style="width: 70px" src="icons/male.svg" />
					</div>
				</div></div>
			</div>
		);
	}else{
		body = (
			<div>
				<div class="sk_bg" style={{width: '40%'}}>&nbsp;</div>
			</div>
		);
	}
	
	return (
		<div class="">
			<div>{body}</div>
			<div><_BasketPanel /></div>
		</div>
	);
}

export class _BasketPanel extends Component {
	static contextType = SessionContext;
	
	state = {
		items: null,
		token: null
	};

	componentDidMount() {
		//not fired cause already sent html render
		let that = this;
		//console.log("success?");CBON
		this.load();
	}

	removeProduct(id) {
		let that = this;
		let session = this.context;
		ProgressiveRequest({
			url: getServer()+'/api/front/basket',
			method: 'post',
			data: {
				action: 'remove',
				id: id
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.list
				});
			},
			received: function(){
				console.log('done');
			}
		});
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		console.log("session", session);
		ProgressiveRequest({
			url: getServer()+'/api/front/basket',
			method: 'post',
			data: {
				action: 'get'
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.list,
					token: data.token
				});

				//setTimeout(function(){window.document.cookie = "session="+data.token;}, 500);
				//c server side donc impossible jpp=><script>?
			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}
	
	render() {
		let that = this;

		//that.load();

		return (
			<div>
				{this.state.items && this.state.items.length > 0 ?
				<>
					<div class="my-4 pt-4"></div>
					<div class="position-fixed w-100 bg-white shadow rounded-top*" style="bottom:0;border-top-left-radius:2rem;border-top-right-radius:2rem;">
						{this.state.items.map((e)=>{
							return <div class="mx-5 my-3 d-flex justify-content-between align-items-center">
									<div><span>{e.quantity}x</span> <span>{e.name}</span></div>
									<div><span>{Number(e.price)*e.quantity},00€</span>
									{/*<button type="button" class="btn btn-danger rounded-pill py-1 px-3 me-2" onClick={()=>{this.removeProduct(e.item_id)}}>Retirer</button>*/}</div>
								</div>
						})}
						<a href="/panier" class="btn btn-b rounded-pill py-1 px-3 mx-5 my-2 d-block">Voir le panier</a>
					</div>
				</>
				: <></>}
			</div>
		);
	}
}
